.instacollage-container {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  text-align: center;
  padding: 20px;
}

.instacollage-container h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.post-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  padding: 10px;
}

.container {
  position: relative;
  flex: 1;
  min-width: 300px;
  max-width: 320px;
}

blockquote.instagram-media {
  width: 100% !important;
  min-width: 300px;
  max-width: 320px;
  margin: 0 auto;
}







/* Media Query for Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .post-container {
      flex-direction: column;
      align-items: center;
  }

  .container {
      min-width: 100%;
      max-width: 100%;
  }
}